import React, { useEffect, useState } from 'react';
import './index.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation, useParams } from 'react-router-dom';

import WithLayout from './components/Layout/Layout';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Following from './pages/Following/Following';
import FollowYourInterests from "./pages/FollowYourInterests/FollowYourInterests";
// import Plansprice from './pages/Plansprice/Plansprice';
import Uspolls from './pages/us-polls/Uspolls';
import IndianPolls from './pages/india-polls/indianPolls';
import Delhipolls from './pages/india-polls/delhi-polls';
// import PaymentSuccess from './pages/PaymentSuccess/PaymentSuccess';
// import PaymentFailure from './pages/PaymentFailure/PaymentFailure';
import Myaccount from './pages/myaccount/Myaccount';
import Subscriptions from './pages/subscriptions/Subscriptions';
import Purchasehistory from './pages/purchasehistory/Purchasehistory';
import Notification from './pages/notification/Notification';
import Settings from './pages/settings/Settings';
import Termcondition from './pages/termcondition/Termcondition';
import Prediction from './pages/prediction/Prediction';
import PredictionListing from './pages/prediction/PredictionListing';
import UsePollPrediction from './pages/use-poll-prediction/UsePollPrediction';
import Polling from './pages/polling/Polling';
import { Toaster } from './components/Toaster/Toaster';
import Topstories from './pages/top-stories/Topstories';
import LocalNews from './pages/local-news/LocalNews';
import Tranding from './pages/trending/Trending';
import MorePosts from './pages/more-posts/MorePosts';
import PickForYou from './pages/picks-for-you/PickForYou';
import ForYou from './pages/for-you/ForYou';
import YourTopic from './pages/your-topics/YourTopic';
import ForYouDetails from './pages/for-you-details/ForYouDetails';
import LatestNews from './pages/latest-news/LatestNews';
import TopStorydetailsId from './pages/top-stories/TopStorydetailsId';
import TrendingDetailsId from './pages/trending/TrendingDetailsId';
import LocalNewsDetailsId from './pages/local-news/LocalNewsDetailsId';
import LatestNewsDetailsId from './pages/latest-news/LatestNewsDetailsId';
import PickForYouDetailsId from './pages/picks-for-you/PickForYouDetailsId';
import MorePostsDetailsId from './pages/more-posts/MorePostsDetailsId';
import YourTopicDetailsId from './pages/your-topics/YourTopicDetailsId';
import PollsNavDetails from './pages/us-polls/general-stories/PollsNavDetails';
import SearchResult from './pages/search-result/SearchResult';
import ContactUs from './pages/contact_us';
import AboutUs from './pages/about_us';
import RefundPolicy from './pages/refund_policy';
import FAQ from './pages/faq';
import TermCondition from './pages/term_of_service';
import PrivacyPolicy from './pages/privacy_policy';
import Notfound from './pages/notfound/notfound';
import CountryListingCom from './components/PridictionComp/countryListingCom';
import NewPrediction from './components/PridictionComp/newPrediction';
import MyPredictions from './components/PridictionComp/myPredictions';

const App = () => {
  const pages = [
    { path: '/login', component: Login, title: 'Login' },
    { path: '/', component: Home, title: "Home" },
    { path: '/following', component: Following, title: "following" },
    { path: "/followyourinterests", component: FollowYourInterests, title: "Follow Your Interest" },
    // { path: "/plansprice", component: Plansprice, title: "Plans" },
    { path: "/top-stories/:slug", component: TopStorydetailsId, title: ":slug" },
    { path: '/trending/:slug', component: TrendingDetailsId, title: ":slug" },
    { path: '/local-news/:slug', component: LocalNewsDetailsId, title: ":slug" },
    { path: '/latest-news/:slug', component: LatestNewsDetailsId, title: ":slug" },
    { path: '/picks-for-you/:slug', component: PickForYouDetailsId, title: ":slug" },
    { path: '/more-posts/:slug', component: MorePostsDetailsId, title: ":slug" },
    { path: '/your-topics/:slug', component: YourTopicDetailsId, title: "Your Topics" },
    { path: '/for-you-details/:id', component: ForYouDetails, title: ":id" },
    { path: '/:slug/general-stories', component: Uspolls, title: ":slug" },
    { path: '/india-polls/polling', component: IndianPolls, title: 'India Polls' },
    { path: '/india-polls/delhi-polls', component: Delhipolls, title: 'Delhi Polls' },
    { path: '/:slug/polling', component: Polling, title: ":slug " },
    // { path: '/PaymentSuccess', component: PaymentSuccess, title: "Payment" },
    // { path: '/PaymentFailure', component: PaymentFailure, title: "Payment" },
    { path: '/myaccount', component: Myaccount, title: "My Account" },
    { path: '/subscriptions', component: Subscriptions, title: "Subscriptions" },
    { path: '/purchasehistory', component: Purchasehistory, title: "Purchase History" },
    { path: '/notification', component: Notification, title: "Notification" },
    { path: '/settings', component: Settings, title: "Settings" },
    { path: '/termcondition', component: Termcondition, title: "Terms And Conditions" },
    // { path: '/prediction', component: PredictionListing, title: "Poll And Predictions" },
    // { path: '/prediction/new', component: NewPrediction, title: "Poll And Predictions" },
    // { path: '/prediction/my_predictions', component: MyPredictions, title: "Poll And Predictions" },
    // { path: '/prediction/country', component: Prediction, title: "Poll And Predictions" },
    // { path: '/prediction', component: Prediction, title: "Poll And Predictions" },
    { path: '/use-poll-prediction/:id', component: UsePollPrediction, title: "Use Poll Prediction" },
    // { path: '/polling', component: Polling,title:"" },
    { path: '/top-stories', component: Topstories, title: "Top Stories" },
    { path: '/local-news', component: LocalNews, title: "Local News" },
    { path: '/latest-news', component: LatestNews, title: "Latest News" },
    { path: '/trending', component: Tranding, title: "Trending" },
    { path: '/more-posts', component: MorePosts, title: "More Post" },
    { path: '/picks-for-you', component: PickForYou, title: "Pics For You" },
    { path: '/for-you', component: ForYou, title: "For You" },
    { path: '/your-topics', component: YourTopic, title: "Your Topics" },
    { path: '/:slug/general-stories/:titleSlug', component: PollsNavDetails, title: ":titleSlug" },
    { path: '/search/search-result/:slug', component: SearchResult, title: "Search Result" },
    // footer pages
    { path: '/about_us', component: AboutUs, title: "Abous Us" },
    { path: '/contact_us', component: ContactUs, title: "Contact Us" },
    { path: '/faq', component: FAQ, title: "Faqs" },
    { path: '/privacy_policy', component: PrivacyPolicy, title: "Privacy Policy" },
    { path: '/refund_policy', component: RefundPolicy, title: "Refund Policy" },
    { path: '/term_of_service', component: TermCondition, title: "Terms Of Service" },

  ];

  // title update component
  const PageWrapper = ({ title, children }) => {
    const { slug, titleSlug } = useParams();

    useEffect(() => {
      let updatedTitle = title;

      if (title?.includes(':slug') && slug) {
        updatedTitle = title.replace(
          ':slug',
          slug
            .replace(/-/g, ' ')
            .toLowerCase()
            .replace(/\b\w/g, char => char.toUpperCase())
        );
      }

      if (title?.includes(':titleSlug') && titleSlug) {
        updatedTitle = title.replace(
          ':titleSlug',
          titleSlug
            .replace(/-/g, ' ')
            .toLowerCase()
            .replace(/\b\w/g, char => char.toUpperCase())
        );
      }

      // Setting the document title
      document.title = `${updatedTitle} | Opinions and Ratings`;
    }, [title, slug, titleSlug]);

    return <>{children}</>;
  };



  return (
    <>
      <Router>
        <Routes>
          {pages?.map(({ path, component: Component, title }, index) => {
            const Layout = Component.Layout || WithLayout;
            return (
              <Route
                key={index}
                path={path}
                element={
                  <PageWrapper title={title}>
                    <Layout>
                      <Component />
                    </Layout>
                  </PageWrapper>
                }
              />
            );
          })}
          <Route path="*" element={<Notfound />} />
        </Routes>
      </Router>
      <Toaster />
      {/* <Router>
     
      <Toaster />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        {pages.map(({ path, component: Component }, index) => {
          const Layout = Component.Layout || WithLayout;
          return (
            <Route
              key={index}
              path={path}
              element={
                <ProtectedRoute>
                  <Layout>
                    <Component />
                  </Layout>
                </ProtectedRoute>
              }
            />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router> */}
    </>
  );
};

export default App;

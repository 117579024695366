// import api from "../api";
// import { GET_PUBLIC_POST_DETAILS, GET_PUBLIC_POST,GET_NAVBAR_MENULIST,GET_PUBLIC_LACAL_NEWS,POST_COMMENT,LATEST_POST,MORE_POST, CATEGORY_DETAIL, GLOBAL_SEARCH, FOR_YOU_DATA, YOUR_TOPIC_DATA, PICK_FOR_YOU_DATA, SHOW_TOSTER, STORE_COMMENT, GET_BACK_TO_DETAILS } from "./type";

import { useNavigate } from "react-router-dom";
import api from "../api";
import { ELECTORAL_VOTES, POLLING_TABLE_LIST, POLLS_AVERAGE_DATA, DELHI_TABLE_LIST, INDIA_POLL_TYPE_LIST, DELHI_POLL_YEARS_LIST } from "./type";

export const getPollingTableList = (payload, candidateName) => async (dispatch) => {
  try {
    const response = await api.get(`/user/polls/view?${payload}&candidateName=${candidateName}`);
    if (response?.status === 200) {
      dispatch({ type: POLLING_TABLE_LIST, payload: response?.data });
    } else if (response?.status === 401) {
      localStorage.removeItem("accessToken")
    }
    else {
    }
  } catch (error) {

  }
};

export const getIndiaPolltypes = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/get-poll-types/view/india`);
    if (response.status === 200) {
      dispatch({ type: INDIA_POLL_TYPE_LIST, payload: response?.data })
    }
    else if (response.status === 401) {
      localStorage.removeItem("accessToken");
    }
  }
  catch (error) {
    // console.error(error);

  }
}

export const getDelhiPolls = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/indian-delhi-polls/view?${payload ? payload : ""}`);
    if (response?.status === 200) {
      dispatch({ type: DELHI_TABLE_LIST, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
      // console.log("")
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    // console.log(error);

  }
}
export const getDelhiPollsYears = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/indian-delhi-polls/year-list`);
    if (response?.status === 200) {
      dispatch({ type: DELHI_POLL_YEARS_LIST, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
      // console.log("")
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    // console.log(error);

  }
}

export const getPollsAverage = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/polls/calculate-poll-average`);
    if (response?.status === 200) {
      dispatch({ type: POLLS_AVERAGE_DATA, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")
    }
    else {
    }
  } catch (error) {

  }
};

export const getElectoralVotes = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/electoral-votes/view`);
    if (response?.status === 200) {
      dispatch({ type: ELECTORAL_VOTES, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")
    }
    else {
    }
  } catch (error) {

  }
};

import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getPollsAverage } from "../../redux/actions/pollingAction";

const PollingChart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPollsAverage());
  }, []);

  const { pollsAverageResponse } = useSelector((state) => state?.pollWeb);

  const generateDateLabels = (updatedAt) => {
    if (!updatedAt) return []; // Handle missing input gracefully

    const startingDate = new Date(updatedAt);
    if (isNaN(startingDate)) return []; // Handle invalid date strings

    const labels = [];
    const options = { year: "numeric", month: "short", day: "numeric" };

    for (let i = 0; i < 12; i++) {
      labels.push(startingDate.toLocaleDateString("en-US", options));
      startingDate.setMonth(startingDate.getMonth() + 1);
    }

    return labels;
  };
  // const generateDateLabels = () => {
  //   const currentDate = new Date();
  //   const labels = [];
  //   const options = { year: "numeric", month: "short", day: "numeric" };

  //   for (let i = 0; i < 12; i++) {
  //     labels.push(currentDate.toLocaleDateString("en-US", options));

  //     currentDate.setMonth(currentDate.getMonth() + 1);
  //   }

  //   return labels;
  // };
  // console.log("pollsAverageResponse ", pollsAverageResponse?.updatedAt);

  const options = {
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "left",
    },
    colors: ["#3C50E0", "#E60A00"],
    chart: {
      fontFamily: "Satoshi, sans-serif",
      height: 335,
      type: "area",
      dropShadow: {
        enabled: true,
        color: "#623CEA14",
        top: 10,
        blur: 4,
        left: 0,
        opacity: 0.1,
      },
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          chart: {
            height: 300,
          },
        },
      },
      {
        breakpoint: 1366,
        options: {
          chart: {
            height: 350,
          },
        },
      },
    ],
    stroke: {
      width: [2, 2],
      curve: "straight",
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
      colors: "#fff",
      strokeColors: ["#3C50E0", "#E60A00"],
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      hover: {
        size: undefined,
        sizeOffset: 5,
      },
    },
    xaxis: {
      type: "category",
      categories: generateDateLabels(pollsAverageResponse?.updatedAt),
      axisBorder: {
        show: false,
      },
      labels: {
        rotate: -25,
        rotateAlways: true,
        style: {
          colors: "#333",
          fontSize: "12px",
        },
        transformOrigin: "left center",
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        style: {
          fontSize: "0px",
        },
      },
      min: 0,
      max: 50,
    },
  };

  const series = [
    {
      name: "Kamala Harris",
      data: [0, pollsAverageResponse?.Dem_Average],
      // data: [20, 36, 28, pollsAverageResponse?.Dem_Average],
    },
    {
      name: "Donald Trump",
      data: [0, pollsAverageResponse?.GOP_Average],
      // data: [10, 19, 15, pollsAverageResponse?.GOP_Average],
    },
  ];

  return (
    <div className="col-span-12 my-6 rounded-lg border border-stroke bg-white px-5 pb-5 pt-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-8">
      <div>
        <div id="chartOne">
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={350}
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default PollingChart;

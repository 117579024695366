import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const PollSelectComp = ({ mapArray }) => {
  const navigate = useNavigate();
  const [hoveredId, setHoveredId] = useState(null);

  return (
    <div>
      <div className="my-2 grid grid-cols-1 min-[380px]:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2">
        {mapArray?.map((res) => {
          const isHovered = hoveredId === res.id;
          return (
            <div
              key={res?.id}
              className="m-auto  min-[380px]:m-0 size-[160px] hover:bg-white bg-[#F7F8FA] hover:border-2 border-red-500 cursor-pointer rounded-xl flex justify-center items-center flex-col"
              onClick={() => navigate(res?.url)}
              onMouseEnter={() => setHoveredId(res?.id)}
              onMouseLeave={() => setHoveredId(null)}
            >
              <img
                src={isHovered ? res?.hoverMap : res?.map}
                alt="indian-flag"
              />
              <p className="mt-5 text-[12px] font-semibold">{res?.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PollSelectComp;

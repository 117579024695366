import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getPollingTableList } from "../../redux/actions/pollingAction";
import DynamicTable from "../DynamicTable/DynamicTable";
import moment from "moment";
import { BASE_URL_PP } from "../../redux/api";

const PollBadge = ({
  Dem_Candidate,
  GOP_Candidate,
  Result_Dem,
  Result_GOP,
}) => {
  let dem_name = Dem_Candidate?.split(" ");
  let gop_name = GOP_Candidate?.split(" ");
  return (
    <div className="overflow-x-auto w-[20rem]">
      <table className="min-w-full  ">
        <tbody className="divide-y divide-transparent">
          <tr className="flex items-baseline">
            {/* Democratic Candidate */}
            <td className="flex-1 text-right pt-1">{dem_name?.[1] ?? ""}</td>

            {/* Results Container */}
            <td className="flex-1 py-1 px-2 ">
              <div className="flex divide-x divide-transparent">
                {/* Democratic Result Button */}
                <button
                  className="flex-1 align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs p-1 px-0 bg-[#E60800] text-nowrap text-white shadow-md focus:opacity-[0.85] active:opacity-[0.85] rounded-r-none"
                  type="button"
                >
                  {Result_Dem ?? 0}
                </button>

                {/* Republican Result Button */}
                <button
                  className="flex-1 align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-1 px-0 text-nowrap bg-[#4252A8] text-white shadow-md focus:opacity-[0.85] active:opacity-[0.85] rounded-l-none"
                  type="button"
                >
                  {Result_GOP ?? 0}
                </button>
              </div>
            </td>

            {/* Republican Candidate */}
            <td className="flex-1 text-left pt-1">{gop_name?.[1] ?? ""}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

function PollingTable() {
  const dispatch = useDispatch();
  const { pollingList } = useSelector((state) => state.pollWeb);
  const [candidateList, setCandidateList] = useState([]);
  const [candidate, setCandidate] = useState("");
  const [update, setUpdate] = useState(false);

  const currentDateTime = new Date();
  // const options = {
  //   month: "short",
  //   day: "2-digit",
  //   year: "numeric",
  //   hour: "numeric",
  //   minute: "numeric",
  //   hour12: true,
  // };
  // console.log("currentDateTime",currentDateTime);

  // const formattedDate =
  //   date.toLocaleString("en-US", options).toUpperCase().replace(",", "") +
  //   " AT " +
  //   date
  //     .toLocaleString("en-US", {
  //       hour: "numeric",
  //       minute: "numeric",
  //       hour12: true,
  //     })
  //     .toUpperCase();
  const dataList = {
    column: [
      {
        title: " DATES",
        field: "createdAt",
        customField: (row) =>
          row?.endDate ? moment(row?.endDate).format("DD/MM/YYYY") : "",
      },
      {
        title: "SAMPLE",
        field: "",
        customField: (row) => row.Poll_Type_LV ?? row.Poll_Type_RV,
      },
      {
        title: "POLLSTER",
        field: "pollsterName",
        customField: (row) => row.pollsterName ?? "-",
      },

      {
        title: "ACCURACIES",
        field: "accuracies",
        customField: (row) => (
          <PollBadge
            Dem_Candidate={row?.Dem_Candidate}
            GOP_Candidate={row?.GOP_Candidate}
            Result_Dem={row?.Dem_Accuracy}
            Result_GOP={row?.GOP_Accuracy}
          />
        ),
      },

      {
        title: "SPONSOR",
        field: "publisher",
        customField: (row) => row.publisher ?? "-",
        // customField: (row) => (row?.createdAt ? moment(row?.createdAt).format('MM/DD/YYYY, hh:mm A') : '')
      },
      {
        title: " POLL %",
        field: "",
        borderleft: true,
        customField: (row) => (
          <PollBadge
            Dem_Candidate={row?.Dem_Candidate}
            GOP_Candidate={row?.GOP_Candidate}
            Result_Dem={row?.Dem_Poll}
            Result_GOP={row?.Dem_Poll}
          />
        ),
      },
      {
        title: " RESULT %",
        field: "",
        borderleft: true,
        customField: (row) => (
          <PollBadge
            Dem_Candidate={row?.Dem_Candidate}
            GOP_Candidate={row?.GOP_Candidate}
            Result_Dem={row?.Result_Dem}
            Result_GOP={row?.Result_GOP}
          />
        ),
      },
      // {
      //   title: "NET RESULT",
      //   field: "result",
      //   customField: (row) => row?.result ?? 0,
      // },
    ],
  };
  const quary = `rows=${200}`;
  useEffect(() => {
    getCandidateList();
    if (quary) {
      dispatch(getPollingTableList(quary, candidate));
    }
  }, [quary, candidate]);

  const getCandidateList = async () => {
    try {
      const response = await fetch(
        `${BASE_URL_PP}/user/polls/view/candidate-list`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      const data = await response.json();
      if (data.status) {
        // console.log(data);
        setCandidateList(data.data);
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handelCandidate = (e) => {
    const candidate = e.target.value;
    // console.log("e.target.value ", e.target.value);

    setCandidate(candidate);
  };

  return (
    <div className="container mx-auto py-4 border rounded-md border-gray-200 capitalize">
      {/* Search and Dropdown */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-4 px-4">
        <div className="flex flex-col mb-4 md:mb-0">
          <div className="text-lg font-semibold pb-1"> polls</div>
          <div className="text-sm text-gray-500">
            Latest Poll:{" "}
            {/* {`${currentDateTime ? moment(currentDateTime).format("llll") : ""}`} */}
            {`${
              pollingList?.[0]?.updatedAt
                ? moment(pollingList?.[0]?.updatedAt).format("llll")
                : ""
            }`}
            {/* {console.log(
              "dataList?.[dataList?.lenght-1] ",
              pollingList?.[pollingList?.length - 1]?.updatedAt
            )} */}
            {/* {formattedDate}  */}
          </div>
        </div>

        {/* Dropdown Menu */}
        <div className="flex flex-col-reverse md:flex-row md:items-center gap-2 mb-4 md:mb-0">
          <div
            className="flex flex-row gap-2 align-middle"
            style={{ alignItems: "center" }}
          >
            <p className="text-sm font-medium uppercase text-gray-400 ">
              candidate
            </p>
            <select
              className="bg-[#E2E7EE] rounded-xl px-3 py-1.5 text-sm"
              onChange={(event) => handelCandidate(event)}
            >
              <option className="text-black" value="">
                All
              </option>
              {candidateList?.map((res, i) => {
                // console.log(candidateList?.[0]?.candidateName);

                return (
                  <option key={i} value={res?.candidateName}>
                    {res?.candidateName}
                  </option>
                );
              })}
              {/* <option className="text-black" value="jeo baden">
                jeo baden
              </option> */}
            </select>
          </div>

          {/* Search Bar */}
          {/* <div className="bg-gray-100 rounded-lg flex items-center gap-2 px-3 py-1.5">
            <FaSearch className="text-gray-600" />
            <div className="h-5 w-px bg-gray-300" />
            <input
              type="search"
              placeholder="Search Keyword"
              className="border-none outline-none text-xs bg-transparent rounded-md py-1 px-2 placeholder-gray-400"
            />
          </div> */}
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <DynamicTable column={dataList?.column} data={pollingList} />
      </div>
    </div>
  );
}

export default PollingTable;

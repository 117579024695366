import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";
import indiaflag from "../../assets/images/flag-img/Mask Group 380ind.png";
import usflag from "../../assets/images/flag-img/Mask Group 380.png";
import canadaFlag from "../../assets/images/flag-img/Mask Group 380-1.png";
import ausFlag from "../../assets/images/flag-img/Mask Group 380-2.png";
import ukFlag from "../../assets/images/flag-img/Mask Group 380-3.png";
import nzFlag from "../../assets/images/flag-img/Mask Group 3800.png";
import { useState } from "react";
import charticon from "../../assets/icons/Path 23145.svg";
import { useNavigate } from "react-router-dom";

function PridictionComp() {
  const followingData = [
    {
      id: 1,
      map: indiaflag,
      hoverMap: indiaflag,
      name: "India Poll Prediction",
      url: "/india-polls/polling",
    },
    {
      id: 2,
      hoverMap: usflag,
      map: usflag,
      name: "US Poll Prediction",
    },
    {
      id: 3,
      hoverMap: canadaFlag,
      map: canadaFlag,
      name: "Canada Poll Prediction",
    },
    {
      id: 4,
      hoverMap: ausFlag,
      map: ausFlag,
      name: "Australia Poll Prediction",
    },
    { id: 5, hoverMap: ukFlag, map: ukFlag, name: "UK Poll Prediction" },
    {
      id: 6,
      hoverMap: nzFlag,
      map: nzFlag,
      name: "NZ Poll Prediction",
    },
  ];

  const navigate = useNavigate();
  const [clickedId, setClickedId] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);

  const handleClick = (id, type) => {
    if (type === "pollprediction") {
      setClickedId(id === clickedId ? null : id);
      setTimeout(() => {
        navigate(`/use-poll-prediction/${id}`);
      }, [200]);
    }
  };

  return (
    <>
      <div className="bg_home px-4 md:px-40 flex flex-col pb-14 bg-white md:bg-[#F7F8FA]">
        <div className="flex flex-row my-5 mx-8">
          <div className="bg-white w-full rounded-xl shadow-sm p-5">
            <div className="text-center">
              <div className="grid grid-cols-1 md:grid-cols-3 justify-between">
                <div className="md:col-span-2">
                  {/* <div className="font-semibold text-[12px] mb-2">
                    <PreSectionHeader title={"Prediction"} />
                  </div> */}
                  <div className="flex gap-2">
                    <div className="text-[12px] border p-2 rounded-2xl cursor-pointer hover:shadow-sm transition-all flex gap-2 items-center">
                      <span>
                        <img src={charticon} alt="" width={12} />
                      </span>{" "}
                      Completed Predictions
                    </div>
                    <div className="text-[12px] border p-2 rounded-2xl  cursor-pointer hover:shadow-sm transition-all flex gap-2 items-center">
                      <span>
                        <img src={charticon} alt="" width={12} />
                      </span>{" "}
                      My Live Predictions
                    </div>
                    <div className="text-[12px] border p-2 rounded-2xl  cursor-pointer hover:shadow-sm transition-all flex gap-2 items-center">
                      <span>
                        <img src={charticon} alt="" width={12} />
                      </span>{" "}
                      Followed Predictions
                    </div>
                    <div className="text-[12px] border p-2 rounded-2xl  cursor-pointer hover:shadow-sm transition-all flex gap-2 items-center">
                      <span>
                        <img src={charticon} alt="" width={12} />
                      </span>{" "}
                      Followed Profiles
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end gap-10 md:gap-0">
                  <button className="text-[12px] border p-2 rounded-2xl bg-[#4252A8] text-white cursor-pointer hover:shadow-sm transition-all flex gap-2 items-center">
                    <img
                      src={charticon}
                      alt="chart-icon"
                      className="w-[12px]"
                    />
                    <span>Prediction History</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="text-[12px] text-[#7F8FA4] my-2">
              Select country to start prediction
            </div>

            <div className="flex flex-col gap-5 py-2 my-2 overflow-y-auto max-h-[calc(100vh-80px)] md:max-h-[calc(100vh-100px)]">
              <div className="">
                <div className="my-2 grid grid-cols-1 min-[380px]:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2">
                  {followingData?.map((res) => {
                    const isHovered = hoveredId === res.id;
                    return (
                      <div
                        key={res?.id}
                        className="m-auto  min-[380px]:m-0 size-[160px] hover:bg-white bg-[#F7F8FA] hover:border-2 border-red-500 cursor-pointer rounded-xl flex justify-center items-center flex-col"
                        onClick={() => navigate(res?.url)}
                        onMouseEnter={() => setHoveredId(res?.id)}
                        onMouseLeave={() => setHoveredId(null)}
                      >
                        <img
                          src={isHovered ? res?.hoverMap : res?.map}
                          alt="indian-flag"
                        />
                        <p className="mt-5 text-[12px] font-semibold">
                          {res?.name}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PridictionComp;

import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getDelhiPolls } from "../../redux/actions/pollingAction";
import DynamicTable from "../DynamicTable/DynamicTable";
import moment from "moment";
import { BASE_URL_PP } from "../../redux/api";

const PollBadge = ({ AAP, BJP, CONG, Others }) => {
  return (
    <div className="overflow-x-auto w-[20rem]">
      <table className="min-w-full  ">
        <tbody className="divide-y divide-transparent">
          <tr className="flex items-baseline">
            {/* Democratic Candidate */}
            {/* <td className="flex-1 text-right pt-1">{"5555"}</td> */}
            {/* Results Container */}
            <td className="flex-1 py-1 px-2 ">
              <div className="grid grid-cols-4 gap-2 divide-x divide-transparent">
                {/* Democratic Result Button */}
                <button
                  className="align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs p-1 px-0 bg-[#FDF000] text-nowrap  shadow-md focus:opacity-[0.85] active:opacity-[0.85] rounded-r-none w-12"
                  type="button"
                >
                  {AAP || 0}
                </button>
                <button
                  className="align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-1 px-0 text-nowrap bg-[#FF671F] text-white shadow-md focus:opacity-[0.85] active:opacity-[0.85] rounded-l-none w-12"
                  type="button"
                >
                  {BJP || 0}
                </button>

                <button
                  className="align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-1 px-0 text-nowrap bg-[#4252A8] text-white shadow-md focus:opacity-[0.85] active:opacity-[0.85] rounded-l-none w-12"
                  type="button"
                >
                  {CONG || 0}
                </button>
                <button
                  className="align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs p-1 px-0 bg-gray-400 text-nowrap text-black shadow-md focus:opacity-[0.85] active:opacity-[0.85] rounded-r-none w-12"
                  type="button"
                >
                  {Others || 0}
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

function PollingTable({ delhiElectionData, type }) {
  const dispatch = useDispatch();
  const [candidateList, setCandidateList] = useState([]);
  const [candidate, setCandidate] = useState("");
  const [update, setUpdate] = useState(false);

  const currentDateTime = new Date();
  const dataList = {
    column: [
      {
        title: " DATES",
        field: "createdAt",
        customField: (row) =>
          row?.publish_date
            ? moment(row?.publish_date).format("DD/MM/YYYY")
            : "",
      },
      {
        title: "SAMPLE",
        field: "",
        customField: (row) => (row?.sample ? row?.sample : "-"),
      },
      {
        title: "POLLSTER",
        field: "pollsterName",
        customField: (row) => row.pollster ?? "-",
      },
      {
        title: "ACCURACIES",
        field: "accuracies",
        customField: (row, idx) => (
          <>
            {idx === 0 && (
              <>
                <div className="text-[12px] grid grid-cols-4 gap-1 text-center ms-0 me-5">
                  <p>AAP</p>
                  <p>BJP</p>
                  <p>CON</p>
                  <p>OTH</p>
                </div>
              </>
            )}

            <PollBadge
              AAP={row?.AAPAccuracy}
              BJP={row?.BJPAccuracy}
              CONG={row?.INCAccuracy}
              Others={row?.OtherAccuracy}
            />
          </>
        ),
      },

      {
        title: "SPONSOR",
        field: "publisher",
        customField: (row) => row.publisher ?? "-",
      },
      ...(type === "poll_prediction"
        ? [
            {
              title: "SEATS",
              field: "Seats",
              customField: (row, idx) => (
                <>
                  {idx === 0 && (
                    <>
                      <div className="text-[12px] grid grid-cols-4 gap-1 ms-0 me-5">
                        <p>AAP</p>
                        <p>BJP</p>
                        <p>CON</p>
                        <p>OTH</p>
                      </div>
                    </>
                  )}

                  <PollBadge
                    AAP={row?.aap_seats}
                    BJP={row?.bjp_seats}
                    CONG={row?.inc_seats}
                    Others={row?.others_seats}
                  />
                </>
              ),
            },
          ]
        : []),
      ...(type === "poll_prediction"
        ? [
            {
              title: "VOTESHARE",
              field: "voteshare",
              customField: (row, idx) => (
                <>
                  {idx === 0 && (
                    <>
                      <div className="text-[12px] grid grid-cols-4 gap-1 ms-0 me-5">
                        <p>AAP</p>
                        <p>BJP</p>
                        <p>CON</p>
                        <p>OTH</p>
                      </div>
                    </>
                  )}

                  <PollBadge
                    AAP={row?.aap_voteshare}
                    BJP={row?.bjp_voteshare}
                    CONG={row?.inc_voteshare}
                    Others={row?.others_voteshare}
                  />
                </>
              ),
            },
          ]
        : []),
      ...(type === "final_result"
        ? [
            {
              title: "SEATS RESULT",
              field: "seatsResult",
              customField: (row, idx) => (
                <>
                  {idx === 0 && (
                    <div className="text-[12px] grid grid-cols-4 gap-1 ms-0 me-5">
                      <p>AAP</p>
                      <p>BJP</p>
                      <p>CON</p>
                      <p>OTH</p>
                    </div>
                  )}
                  <PollBadge
                    AAP={row?.aap_seat_result}
                    BJP={row?.bjp_seat_result}
                    CONG={row?.inc_seat_result}
                    Others={row?.others_seat_result}
                  />
                </>
              ),
            },
          ]
        : []), // Only include the column if showSeatsResult is true
      // {
      //   title: "SEATS RESULT",
      //   field: "seatsResult",
      //   customField: (row, idx) => (
      //     <>
      //       {idx === 0 && (
      //         <>
      //           <div className="text-[12px] grid grid-cols-4 gap-1 ms-0 me-5">
      //             <p>AAP</p>
      //             <p>BJP</p>
      //             <p>CON</p>
      //             <p>OTH</p>
      //           </div>
      //         </>
      //       )}

      //       <PollBadge
      //         AAP={row?.aap_seat_result}
      //         BJP={row?.bjp_seat_result}
      //         CONG={row?.inc_seat_result}
      //         Others={row?.others_seat_result}
      //       />
      //     </>
      //   ),
      // },

      ...(type === "final_result"
        ? [
            {
              title: "VOTESHARE RESULT",
              field: "voteshareResult",
              customField: (row, idx) => (
                <>
                  {idx === 0 && (
                    <>
                      <div className="text-[12px] grid grid-cols-4 gap-1 ms-0 me-5">
                        <p>AAP</p>
                        <p>BJP</p>
                        <p>CON</p>
                        <p>OTH</p>
                      </div>
                    </>
                  )}

                  <PollBadge
                    AAP={row?.aap_voteshare_result}
                    BJP={row?.bjp_voteshare_result}
                    CONG={row?.inc_voteshare_result}
                    Others={row?.others_voteshare_result}
                  />
                </>
              ),
            },
          ]
        : []),
      // {
      //   title: " POLL %",
      //   field: "",
      //   borderleft: true,
      //   customField: (row, idx) => (
      //     <>
      //       {idx === 0 && (
      //         <>
      //           <div className="text-[12px] grid grid-cols-4 gap-1">
      //             <p>AAP</p>
      //             <p>BJP</p>
      //             <p>CON</p>
      //             <p>OTH</p>
      //           </div>
      //         </>
      //       )}
      //       <PollBadge
      //         AAP={row?.aap_seats}
      //         BJP={row?.bjp_seats}
      //         CONG={row?.inc_seats}
      //         Others={row?.others_seats}
      //       />
      //     </>
      //   ),
      // },
      // {
      //   title: " RESULT %",
      //   field: "",
      //   borderleft: true,
      //   customField: (row, idx) => (
      //     <>
      //       {idx === 0 && (
      //         <>
      //           <div className="text-[12px] grid grid-cols-4 gap-1">
      //             <p>AAP</p>
      //             <p>BJP</p>
      //             <p>CON</p>
      //             <p>OTH</p>
      //           </div>
      //         </>
      //       )}
      //       <PollBadge
      //         AAP={row?.aap_voteshare_result}
      //         BJP={row?.bjp_voteshare_result}
      //         CONG={row?.inc_voteshare_result}
      //         Others={row?.others_voteshare_result}
      //       />
      //     </>
      //   ),
      // },
    ],
  };
  const quary = `rows=${200}`;
  useEffect(() => {
    // dispatch(getDelhiPolls());
    if (quary) {
    }
  }, []);

  const handelCandidate = (e) => {
    const candidate = e.target.value;
    setCandidate(candidate);
  };

  return (
    <div className="container mx-auto py-4 border rounded-md border-gray-200 capitalize">
      {/* Search and Dropdown */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-4 px-4">
        <div className="flex flex-col mb-4 md:mb-0">
          <div className="text-lg font-semibold pb-1"> polls</div>
          <div className="text-sm text-gray-500">
            Latest Poll:{" "}
            {`${
              delhiElectionData?.[0]?.updatedAt
                ? moment(delhiElectionData?.[0]?.updatedAt).format("llll")
                : ""
            }`}
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-scroll scrollbar-mob">
        <DynamicTable column={dataList?.column} data={delhiElectionData} />
      </div>
    </div>
  );
}

export default PollingTable;

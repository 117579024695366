import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import delhimap from "../../assets/icons/delhiMap.svg";
import PollingTableNew from "../../components/PollingTable/PollingTableNew";
import appicon from "../../assets/icons/Aam_Aadmi_Party_flag_(2).svg@2x.png";
import congFlag from "../../assets/icons/congFlag.svg";
import bjpFlag from "../../assets/icons/BJP_Flag.svg";
import othersFlag from "../../assets/icons/0thersflag.svg";
import { useEffect, useState } from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { getIndiaPollResults } from "../../redux/actions/privateApiAction";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {
  getDelhiPolls,
  getDelhiPollsYears,
} from "../../redux/actions/pollingAction";
// import { Tooltip } from "@material-tailwind/react";

const Delhipolls = () => {
  const location = useLocation();
  let { slug } = useParams();
  const isVerified = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { indiaPollsResult } = useSelector((state) => state?.privateReducer);
  const { delhiPollsYearsResponse, delhiElectionData } = useSelector(
    (state) => state.pollWeb
  );
  const [searchData, setSearchData] = useState({
    year: delhiPollsYearsResponse[0]?.year || "2025",
    type: "final_result",
  });

  // console.log(" 0000 ", delhiPollsYearsResponse[0]?.year);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!isVerified) {
      navigate("/login");
    }
    dispatch(getIndiaPollResults()).then(() => {
      setLoading(false);
      dispatch(getDelhiPolls());
    });
    dispatch(getDelhiPollsYears(searchData?.year));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value, "clo");
    setSearchData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    const payload = `type=${searchData?.type}&year=${searchData?.year}`;
    // if (searchData?.type === "poll_prediction") {
    dispatch(getDelhiPolls(payload));
    // }
    dispatch(getIndiaPollResults(payload));
    // setSearchData({
    //   year: "",
    //   type: "",
    // });
  };

  const pieData = [
    {
      name: "APP",
      value: parseFloat(indiaPollsResult?.aapSeats),
      female: parseFloat(indiaPollsResult?.appFemaleVShares),
      seat: parseFloat(indiaPollsResult?.aapSeats),
      male: parseFloat(indiaPollsResult?.appMaleVShares),
    },
    {
      name: "BJP",
      value: parseFloat(indiaPollsResult?.bjpSeats),
      female: parseFloat(indiaPollsResult?.bjpFemaleVShares),
      seat: parseFloat(indiaPollsResult?.bjpSeats),
      male: parseFloat(indiaPollsResult?.bjpMaleVShares),
    },
    {
      name: "CON",
      value: parseFloat(indiaPollsResult?.incSeats),
      female: parseFloat(indiaPollsResult?.incFemaleVShares),
      seat: parseFloat(indiaPollsResult?.incSeats),
      male: parseFloat(indiaPollsResult?.incMaleVShares),
    },
    {
      name: "OTH",
      value: parseFloat(indiaPollsResult?.otherSeats),
      female: parseFloat(indiaPollsResult?.othersFemaleVShares),
      seat: parseFloat(indiaPollsResult?.otherSeats),
      male: parseFloat(indiaPollsResult?.othersMaleVShares),
    },
  ];
  const COLORS = ["#FDF000", "#FF671F", "#4251A8", "#AAAAAA"];
  const barData = [
    {
      name: "Aam Aadmi Party",
      result: indiaPollsResult?.aapVoteShares,
      // ? indiaPollsResult?.aapVoteShares
      // : indiaPollsResult?.aapSeatResults,
      color: "bg-gradient-to-r via-amber-200 from-[#FDF000] to-white",
      icon: appicon,
    },
    {
      name: "Bhartiya Janta Party",
      result: indiaPollsResult?.bjpVoteShares,
      // ? indiaPollsResult?.bjpVoteShares
      // : indiaPollsResult?.bjpSeatResults,
      color: "bg-gradient-to-r via-[#FF671F] from-[#FF671F] to-orange-100",
      icon: bjpFlag,
    },
    {
      name: "Congress",
      result: indiaPollsResult?.incVoteShares,
      // ? indiaPollsResult?.incVoteShares
      // : indiaPollsResult?.incSeatResults,
      color: "bg-gradient-to-r via-indigo-400 from-indigo-800 to-indigo-200",
      icon: congFlag,
    },
    {
      name: "Others",
      result: indiaPollsResult?.otherVoteShares,
      // ? indiaPollsResult?.otherVoteShares
      // : indiaPollsResult?.othersSeatResults,
      color: "bg-gradient-to-r from-gray-500 to-white",
      icon: othersFlag,
    },
  ];

  return (
    <div className="bg_home flex flex-col pb-8">
      <div className="flex flex-row px-6 min-[955px]:px-40 gap-4 text-[12px] font-semibold py-2 bg-[#E2E7EE] ">
        <Link
          to={`/india-polls/general-stories`}
          className={` ${
            location.pathname === `/india-polls/general-stories`
              ? "font-bold "
              : "text-[#7F8FA4]"
          }`}
        >
          General Stories
        </Link>
        <Link
          to={`/india-polls/polling`}
          className={` ${
            location.pathname === `/india-polls/delhi-polls`
              ? "font-bold "
              : "text-[#7F8FA4]"
          }`}
        >
          Polls
        </Link>
        {/* <Link
          //   to={`/${slug}/polling`}
          className={` ${
            location.pathname === `/india-polls/prediction`
              ? "font-bold "
              : "text-[#7F8FA4]"
          }`}
        >
          Predictions
        </Link> */}
      </div>
      <div className="bg_home md:bg-[#F7F8FA] px-4 min-[955px]:px-40 flex flex-col pb-6 md:py-5">
        <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5 overflow-hidden">
          <div className="h-auto pb-5">
            <div className="flex flex-col gap-1">
              <div className="flex flex-row justify-start items-center gap-2">
                <div className="size-[20px]">
                  <img
                    src={delhimap}
                    alt="delhi-map"
                    className="object-contain"
                  />
                </div>
                <div className="text-[20px] font-semibold">Delhi Polls</div>
              </div>
            </div>
          </div>
          <div className="h-auto">
            <div className="my-5">
              <div className="sm:flex justify-start items-center mb-4 gap-6">
                <div className="my-5">
                  <FormControl
                    className="w-[7rem] md:w-[15rem]"
                    variant="outlined"
                  >
                    <InputLabel id="select-Type-label">Select Type</InputLabel>
                    <Select
                      labelId="select-Type-label"
                      id="selectType"
                      name="type"
                      value={searchData?.type}
                      onChange={handleChange}
                      label="Select Type"
                      sx={{ borderRadius: "0.75rem" }}
                    >
                      <MenuItem value="final_result">Final Result</MenuItem>
                      <MenuItem value="poll_prediction">
                        Poll Prediction
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="my-5">
                  <FormControl
                    className="w-[7rem] md:w-[15rem]"
                    variant="outlined"
                  >
                    <InputLabel id="select-Year-label">Select Year</InputLabel>
                    <Select
                      labelId="select-Year-label"
                      id="selectYear"
                      name="year"
                      value={searchData?.year}
                      label="Select Year"
                      onChange={handleChange}
                      sx={{ borderRadius: "0.75rem" }}
                    >
                      {delhiPollsYearsResponse?.map((item) => {
                        return (
                          <MenuItem value={item?.year}>{item?.year}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <button
                  className="px-12 py-3 bg-[#E4002B] text-white rounded-lg"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="text-[21px] font-semibold">
              {delhiElectionData ? "Average of Polls" : "Voteshare"}
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex flex-row justify-between items-center mb-14">
                <div className="grid grid-cols-1 md:grid-cols-2 w-full lg:w-[80%] items-center">
                  <div className="space-y-4 md:hidden">
                    {barData.map((party, index) => (
                      <div key={index} className="flex items-center gap-2 ms-5">
                        <div className="min-w-14 max-w-14 mt-3">
                          <img src={party.icon} alt={party.name} />
                        </div>
                        <div className="text-[14px] font-semibold">
                          <p className="mb-1">{party.name}</p>
                          <div
                            className={`${
                              party.color
                            } min-w-[3.5rem] max-w-[26rem]  w-[${parseInt(
                              Math.max(5, party?.result)
                            )}rem] flex justify-end  font-bold py-1 px-2`}
                            style={{
                              width: `${Math.max(5, party?.result)}vw`,
                            }}
                          >
                            {party?.result || 16 - index * 4}%
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="space-y-4 hidden md:block">
                    {barData.map((party, index) => (
                      <div key={index} className="flex items-center gap-2 ms-5">
                        <div className="min-w-14 max-w-14 mt-3">
                          <img src={party.icon} alt={party.name} />
                        </div>
                        <div className="text-[14px] font-semibold">
                          <p className="mb-1">{party.name}</p>
                          <div
                            className={`${
                              party.color
                            } min-w-[4rem] max-w-[26rem]  w-[${parseInt(
                              Math.max(5, party?.result)
                            )}rem] flex justify-end  font-bold py-1 px-2`}
                            style={{
                              width: `${
                                Math.max(5, party?.result) / 2.1 ||
                                16 - index * 4
                              }vw`,
                            }}
                          >
                            {party?.result || 16 - index * 4}%
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {!loading ? (
                    <div className="w-[85vw] md:w-[38vw] flex justify-end">
                      <PieChart width={642} height={330}>
                        <Pie
                          data={pieData}
                          cx={300}
                          cy={140}
                          innerRadius={80}
                          outerRadius={135}
                          fill="#8884d8"
                          paddingAngle={5}
                          labelLine={false}
                          dataKey="value"
                          label={({
                            cx,
                            cy,
                            midAngle,
                            innerRadius,
                            outerRadius,
                            index,
                          }) => {
                            const RADIAN = Math.PI / 180;
                            const radius =
                              innerRadius + (outerRadius - innerRadius) * 1.1;
                            const x =
                              cx + radius * Math.cos(-midAngle * RADIAN);
                            const y =
                              cy + radius * Math.sin(-midAngle * RADIAN);
                            const sliceData = pieData[index];
                            return (
                              <g>
                                {sliceData?.seat > 0 && (
                                  <circle
                                    cx={x}
                                    cy={y - 1}
                                    r={25}
                                    fill="red"
                                    stroke="white"
                                    strokeWidth={4}
                                  />
                                )}
                                {sliceData?.seat > 0 && (
                                  <text
                                    x={x}
                                    y={y - 1}
                                    fill="white"
                                    textAnchor="middle"
                                    dominantBaseline="central"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`${sliceData?.seat}`}
                                  </text>
                                )}
                              </g>
                            );
                          }}
                        >
                          {pieData?.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <text
                          x={305}
                          y={180}
                          textAnchor="middle"
                          dominantBaseline="central"
                          style={{
                            fontSize: "21px",
                            fontWeight: "bold",
                            fill: "#333",
                          }}
                        >
                          SEATS
                        </text>

                        <Legend
                          verticalAlign="top"
                          align="right"
                          height={36}
                          wrapperStyle={{
                            top: 350,
                            right: 170,
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        />
                      </PieChart>
                    </div>
                  ) : (
                    <>
                      <div className="w-[85vw] md:w-[38vw] h-[20.5rem] flex justify-center items-center relative">
                        <div className="skeleton-loader size-60 rounded-full relative">
                          <div className="w-16 h-6 rotate-12 bg-white absolute top-24"></div>
                          <div className="w-6 h-20 rotate-[60deg] bg-white absolute top-[3.5rem] left-[12rem]"></div>
                          <div className="w-6 h-20 rotate-[150deg] bg-white absolute bottom-[0.5rem] left-[10rem]"></div>
                          <div className="size-36 rounded-full bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* Polling chart end */}
            {/* {delhiElectionData && ( */}
            <div
              className={` 
                  flex flex-col gap-3
              `}
            >
              <div className="text-[21px] font-semibold">Polling Table</div>
              <PollingTableNew
                delhiElectionData={delhiElectionData}
                type={searchData.type}
              />
            </div>
            {/* )} */}
            <div className="py-6 hidden">
              <div className="text-[12px] text-gray-700 font-semibold">
                Summary
              </div>
              <p
                className="text-[12px] text-gray-500 py-2 leading-5 text-justify"
                onMouseEnter={() => {
                  console.log();
                }}
              >
                {/* Summary content */}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                vel auctor magna, ut tristique sapien. Nunc ut ligula placerat,
                tempus risus sed, vehicula massa. Sed rutrum ultrices libero,
                sit amet suscipit magna vulputate vel. Curabitur hendrerit
                ornare risus. Praesent et eleifend neque. Nam eget massa massa.
                Fusce orci augue, egestas vitae ultrices eu, facilisis at lacus.
                Donec condimentum eros at justo hendrerit, vitae congue purus
                eleifend. Nam facilisis nulla sem, ac bibendum felis accumsan
                at. Proin hendrerit ac ipsum non lobortis. Proin eget porta
                turpis, in rhoncus massa. Donec sit amet nibh et mi congue
                pretium quis eu nibh. Pellentesque tempus sem ac orci rhoncus
                hendrerit. Aenean vitae orci ut diam sollicitudin tincidunt sit
                amet ac lectus. Proin felis sem, pellentesque sit amet diam
                vitae, suscipit fermentum est. Vestibulum quis vulputate felis.
                Nullam ut orci non metus eleifend condimentum eget et mi. Morbi
                rhoncus commodo est sed sodales. Vestibulum vehicula ligula
                risus, ut vulputate nulla pretium vel. Quisque semper nulla
                lorem, sed ultricies lectus venenatis nec. Proin non mi pretium,
                cursus ligula ac, suscipit nisi. Pellentesque mollis sodales
                ligula a feugiat. Sed in neque quis dolor ullamcorper auctor
                quis ac ipsum. Quisque at nisi sed sapien aliquam laoreet eget
                eget dolor. Donec euismod enim lectus, eu elementum mi facilisis
                ut. Curabitur ultrices nulla a magna laoreet sollicitudin.
              </p>
            </div>

            {/* prediction Modal  */}

            {/* <div className="py-6">
              <div className="flex justify-between items-center">
                <div className="text-[16px]">
                  <p>Users Predictions</p>
                </div>
                <button
                  disabled
                  className="px-3 py-2 bg-[#012169] text-white text-[12px] rounded-full opacity-35"
                >
                  view All
                </button>
              </div>
              <div className="flex ">
                <div className="relative w-[21rem] h-[10rem] rounded-2xl overflow-hidden bg-gray-50 cursor-pointer">
                  <div className="p-1 text-[10px] bg-black text-white flex justify-start gap-3 items-center font-semibold">
                    <div className="rounded-full border size-6 bg-gray-300"></div>
                    <p>Subhas’s Arizona Prediction</p>
                  </div>
                  <div className="grid grid-cols-1 mx-2 ">
                    <div className="flex gap-1 h-6 items-end">
                      <p className="w-4 text-[8px]">100</p>
                      <div className="border-b border-black w-full"></div>
                    </div>
                    <div className="flex gap-1 h-6 items-end">
                      <p className="w-4 text-[8px]">75</p>
                      <div className="border-b border-black w-full"></div>
                    </div>
                    <div className="flex gap-1 h-6 items-end">
                      <p className="w-4 text-[8px]">50</p>
                      <div className="border-b border-black w-full"></div>
                    </div>
                    <div className="flex gap-1 h-6 items-end">
                      <p className="w-4 text-[8px]">25</p>
                      <div className="border-b border-black w-full"></div>
                    </div>
                    <div className="flex gap-1 h-6 items-end">
                      <p className="w-4 text-[8px]">0</p>
                      <div className="border-b-4 border-gray-500 w-full"></div>
                    </div>
                  </div>
                  <div className="h-[6.25rem] pb-2 flex justify-center gap-5 mx-8 absolute top-[52px] left-14 ">
                    <div className="flex items-end justify-center gap-1 h-[6rem]">
                      <div className="border w-6 h-[5rem] bg-[#4252A8]"></div>
                      <div className="border w-6 h-[5.6rem] bg-[#E60800]"></div>
                    </div>
                    <div className="flex items-end justify-center gap-1 h-[6rem]">
                      <div className="border w-6 h-[4.6rem] bg-[#4252A8]"></div>
                      <div className="border w-6 h-[5.4rem] bg-[#E60800]"></div>
                    </div>
                    <div className="flex items-end justify-center gap-1 h-[6rem]">
                      <div className="border w-6 h-[0.6rem] bg-[#4252A8]"></div>
                      <div className="border w-6 h-[1rem] bg-[#E60800]"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delhipolls;

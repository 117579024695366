import React, { useEffect, useState } from "react";
import SecHead from "../SectionHeader/SecHead";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import { useDispatch, useSelector } from "react-redux";
import { getyourTopic } from "../../redux/actions/publicAction";
import { Link } from "react-router-dom";
import moment from "moment";
import SideShimmer from "../../common/SideShimmer";

const YourTopicsHomeComp = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { yourTopic } = useSelector((state) => state.publicReducer);

  useEffect(() => {
    dispatch(getyourTopic(6)).finally(() => setLoading(false));
  }, []);
  // console.log("yourTopic",yourTopic);
  const isVerified = localStorage.getItem("accessToken");
  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  function cleanString(str) {
    return str.replace(/[^a-zA-Z0-9-_]/g, "");
  }

  return (
    <div className="w-full">
      <div className="flex w-full flex-col md:flex-row items-start mb-1 justify-between py-4">
        <div>
          <SecHead
            title={"Your Topics"}
            redirectLink="/your-topics"
            fontSize={2}
          />
          <p className="recme_shown">Recommended based on your interests</p>
        </div>
      </div>
      <div className="flex md:grid md:grid-cols-3 gap-5 overflow-x-auto md:overflow-x-hidden scrollbar-hide">
        {Array.isArray(yourTopic) &&
          yourTopic.slice(0, 6).map((item, index) => (
            <div
              key={item.id}
              className="flex-shrink-0 bg-white rounded-xl shadow-sm p-3 md:p-5 w-80 md:w-auto px-4 py-4"
            >
              <Link to={isVerified ? `/your-topics/${item?.slug}` : "/login"}>
                <div
                  className={`flex flex-row items-start justify-between  gap-5 py-2`}
                >
                  <div className="flex flex-col ">
                    <span className="opacity-95">
                      <CategoryPolls
                        title={item?.category?.name}
                        id={item?.category?.id}
                        img={item?.category?.icon}
                      />
                    </span>
                    <span
                      className="text-gray-800 my-3 text-[16px] font-semibold tracking-normal overflow-hidden min-h-[4rem]"
                      style={{ lineHeight: 1.4 }}
                    >
                      {item?.title}
                    </span>
                    <span className="date_shown">
                      {moment(item?.updatedAt).format("MMMM D, YYYY")}
                    </span>
                  </div>
                  <img
                    src={item?.file}
                    alt={item?.fileId}
                    className="w-20 md:w-16 h-16 object-cover rounded-xl mt-2"
                  />
                </div>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default YourTopicsHomeComp;

import { Link, useLocation, useParams } from "react-router-dom";
import WithLayout from "../../components/Layout/Layout";
import LocalNews from "../../components/HomeComp/LocalNewsHomeComp";
import TopStoriesDetailsCom from "../../components/HomeComp/TopStoriesDetailsCom/TopStoriesDetailsCom";
import { useEffect, useState } from "react";
import TopStoriesDetailsMobileCom from "../../components/HomeComp/TopStoriesDetailsCom/TopStoriesDetailsMobileCom";
import moment from "moment";
import CategoryPolls from "../../components/CategoryPolls/CategoryPolls";
import SecHead from "../../components/SectionHeader/SecHead";
import { getPublicLatestPost } from "../../redux/actions/publicAction";
import { useDispatch, useSelector } from "react-redux";
import LatestPost from "../../components/HomeComp/LatestPostHomeComp";
import LatestNewsBottom from "../../components/commonComponenets/LatestNewsBottom";

function MorePostsDetailsId() {
  const { id, slug } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  // console.log("id",id);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const { latestPost } = useSelector((state) => state.publicReducer);
  useEffect(() => {
    dispatch(getPublicLatestPost(3));
  }, []);

  window.scrollTo(0, 0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isVerified = localStorage.getItem("accessToken");
  // bradcumb
  const pathSegments = location.pathname.split("/").filter(Boolean);
  // pathSegments.pop();
  const breadCumb = [
    { id: 1, name: isVerified ? "For You" : "News", link: "/" },
    ...pathSegments.map((segment, index) => {
      // Construct link for each segment
      const link = `/${pathSegments.slice(0, index + 1).join("/")}`;
      // console.log("link", link);

      return { id: index + 2, name: formatBreadcrumbName(segment), link };
    }),
  ];
  function formatBreadcrumbName(name) {
    return name
      .replace(/-/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <>
      <div className="bg_home  bg-[#F7F8FA] md:px-40 flex flex-col pb-14">
        <div className="flex-[2] ">
          {isMobile ? (
            <div className="flex flex-col  gap-5 ">
              <div className=" flex flex-col ">
                <TopStoriesDetailsMobileCom postDetailsid={slug} />
              </div>
            </div>
          ) : (
            <>
              <div className="py-4">
                {breadCumb.map((item, index) => (
                  <span
                    key={item.id}
                    className={`text-[10px] font-medium  ${
                      index === breadCumb.length - 1 ? "" : "opacity-60"
                    }`}
                  >
                    {index > 0 && " > "}
                    {index < breadCumb?.length - 1 ? (
                      <Link to={item.link} className="breadcrumb-link">
                        {item.name}
                      </Link>
                    ) : (
                      <>{item.name}</>
                    )}
                  </span>
                ))}
              </div>
              <div className="flex flex-col min-[1040px]:flex-row gap-5 mb-5">
                <div className="flex-[2] flex flex-col gap-5 lg:w-3/5">
                  <TopStoriesDetailsCom postDetailsid={slug} />
                </div>

                <div className="flex-1 flex flex-col gap-5">
                  <div className="bg-white w-full p-3 md:p-5 px-4 rounded-lg sticky top-[3rem] ">
                    <SecHead
                      title={"Latest Posts"}
                      redirectLink="/latest-news"
                    />

                    <div className=" ">
                      {Array.isArray(latestPost) &&
                        latestPost.map((item, index) => (
                          <Link
                            // to={`/local-news-details/${item?.uuid}`}
                            to={`/latest-news/${item?.slug}`}
                          >
                            <div
                              key={item?.id}
                              className={`flex flex-row items-start  gap-4 md:gap-4 py-2 border-b border-gray-300   ${
                                index === latestPost.length - 1
                                  ? "border-none"
                                  : "border-b"
                              } justify-between`}
                            >
                              <div className="flex flex-col mt-2 md:mt-0 md:pt-1 md:gap-2 md:opacity-90">
                                <CategoryPolls
                                  title={item?.category?.name}
                                  id={item?.category?.id}
                                  img={item?.category?.icon}
                                />
                                <span
                                  className=" text-[14px] text-[#1A1A1A] open-sans-600  tracking-tight"
                                  style={{ lineHeight: 1.4 }}
                                >
                                  {item?.title}
                                </span>

                                <span className="date_shown mt-4">
                                  {" "}
                                  {moment(item[0]?.updatedAt).format(
                                    "MMMM D, YYYY"
                                  )}
                                </span>
                              </div>

                              <img
                                src={item?.file ?? ""}
                                alt={item?.fileId}
                                className="  w-20 mt-2 md:w-20 h-auto object-cover rounded-md "
                              />
                            </div>
                          </Link>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* latest post common componenet */}
          <LatestNewsBottom />
        </div>
      </div>
    </>
  );
}
MorePostsDetailsId.Layout = WithLayout;
export default MorePostsDetailsId;

import { useEffect } from "react";
import Box from "@mui/material/Box";
import leftPredictionPerson from "../../assets/images/Kamala harris pic.png";
import rightPredictionPerson from "../../assets/images/Donald trump pic.png";
import { useDispatch, useSelector } from "react-redux";
import { getElectoralVotes } from "../../redux/actions/pollingAction";

function ElectionSeatPredictionComp({ type = "popular" }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getElectoralVotes());
  }, []);

  const { electoralVotesResponse } = useSelector((state) => state?.pollWeb);

  function calculateSeatsPercentage(candidate1Seats, candidate2Seats) {
    const totalSeats = 538;
    const candidate1Percentage = (candidate1Seats / totalSeats) * 100;
    const candidate2Percentage = (candidate2Seats / totalSeats) * 100;

    return {
      candidate1Percentage,
      candidate2Percentage,
    };
  }

  const percentages = calculateSeatsPercentage(
    electoralVotesResponse?.votesForHarris,
    electoralVotesResponse?.votesForTrump
  );

  // const generateGradient = () => {
  //   return `linear-gradient(to right, rgb(66,82,168) ${percentages.candidate1Percentage}%, rgb(255, 61, 61) ${percentages.candidate1Percentage}%)`;
  // };

  const generateGradient = () => {
    return `linear-gradient(to right, rgb(66,82,168) 0%, rgba(66,82,168,0.15) ${percentages.candidate1Percentage}%, rgba(2255, 61, 61,0.15) ${percentages.candidate1Percentage}%, rgb(255, 61, 61) 100%)`;
  };

  return (
    <>
      <div className="flex flex-row justify-center gap-9 py-10 my-10">
        <img
          src={leftPredictionPerson}
          alt="Kamala Harris"
          className="w-24 h-24"
        />
        <span className="flex flex-col text-center justify-center">VS</span>
        <img
          src={rightPredictionPerson}
          alt="Donald Trump"
          className="w-24 h-24"
        />
      </div>
      <div className="flex justify-center">
        <Box
          sx={{
            width: 600,
            height: 20,
            position: "relative",
            paddingLeft: "2px",
            paddingRight: "2px",
            background: generateGradient(),
          }}
        >
          <div className="text-[#4252A8] text-3xl absolute left-0 bottom-8 ">
            {type === "popular"
              ? `${Math?.round(percentages.candidate1Percentage) || 50}%`
              : electoralVotesResponse?.votesForHarris}
          </div>
          <div className="text-3xl text-[#E60A00] absolute right-0 bottom-8">
            {type === "popular"
              ? `${Math?.round(percentages.candidate2Percentage) || 50}%`
              : electoralVotesResponse?.votesForTrump || 269}
          </div>
          <div className="absolute left-0 top-8 text-left">
            <div className="">Kamala Harris</div>
          </div>
          <div className="absolute left-1/2 top-12 transform -translate-x-1/2 text-xs text-gray-500 whitespace-nowrap">
            {type === "popular" ? "50%" : "270 to win"}
          </div>
          <div className="absolute right-0 top-8 text-right">
            <div className="">Donald Trump</div>
          </div>
          {/* Pointer in the middle of the bar */}
          <div
            style={{
              position: "absolute",
              top: "-60%",
              left: "50%",
              transform: "translateX(-50%)",
              width: "2px",
              height: "60px",
              backgroundColor: "#000",
            }}
          ></div>
        </Box>
      </div>
    </>
  );
}

export default ElectionSeatPredictionComp;
